import React, { useState } from "react"
import Hero from "../CaseStudy/Hero"
import AboutProject from "../CaseStudy/AboutProject"
import Features from "../CaseStudy/Features"
import TechStack from "../CaseStudy/TechStack"
import AppDisplayImage from "../CaseStudy/AppDisplayImage"
import KeyChallengaes from "../CaseStudy/KeyChallanges"
import Results from "../CaseStudy/Results"
import SolutionSection from "../CaseStudy/SolutionSection"
import BusinessNeeds from "../CaseStudy/BusinessNeeds"
import ContactUs from "../CaseStudy/Features/ContactUs"
import {
  techTags,
  aboutSections,
  techImages,
  aboutProjectIcons,
  keyChallenges,
  solutions,
  featuresList,
  resultsList,
  businessNeedsList,
} from "./data"

function IbdSports() {
  const [active, setActive] = useState(featuresList[0])

  const clickHandler = feature => {
    setActive(feature)
  }
  return (
    <div>
      <Hero
        techTags={techTags}
        aboutSections={aboutSections}
        title="Productivity Solution for High-Volume Photography"
        imageUrl="/caseStudy/Ibdsport/IbdImage.png"
        logoUrl="/caseStudy/Ibdsport/IBDLogo.svg"
      />
      <AboutProject
        text="Revolutionizing the high-volume photography process by harnessing innovative technology and delivering exceptional customer service, providing a seamless and personalized experience that captures and preserves memories for future generations."
        data={aboutProjectIcons}
      />
      <AppDisplayImage url="/caseStudy/Ibdsport/IbdMyProject.png" />
      <KeyChallengaes
        sideImage={"/caseStudy/Ibdsport/IbdKeyChallenges.png"}
        data={keyChallenges}
        showFullRightImage
        name="ibdsports"
      />
      <AppDisplayImage url="/caseStudy/Ibdsport/IbdBelowKey.png" />
      <SolutionSection data={solutions} />
      <AppDisplayImage url="/caseStudy/Ibdsport/IbdOurSolutions.png" />
      <Features
        data={featuresList}
        onClickHandler={clickHandler}
        active={active}
      />
      <Results data={resultsList} />
      <TechStack data={techImages} page="container-2" />
      <BusinessNeeds data={businessNeedsList} />
      <ContactUs className="no-padding" />
    </div>
  )
}

export default IbdSports
