export const techTags = [
  {
    id: 1,
    name: "Product Design",
  },
  {
    id: 3,
    name: "Front-End",
  },
  {
    id: 4,
    name: "Back-End",
  },
  {
    id: 5,
    name: "DevOps",
  },
  {
    id: 6,
    name: "QA",
  },
]

export const aboutSections = [
  {
    id: 1,
    heading: "Industry Vertical",
    description: "MarTech, Photography, Sports",
  },
  {
    id: 2,
    heading: "Business Model",
    description: "B2B SaaS, B2C",
  },
  {
    id: 3,
    heading: "Our Work",
    description:
      "Product Design, Custom Software Development, DevOps, QA and Testing",
  },
]

export const techImages = [
  {
    id: 1,
    icon: "/caseStudy/Ibdsport/tech1.svg",
  },
  {
    id: 2,
    icon: "/caseStudy/Ibdsport/tech2.svg",
  },
  {
    id: 3,
    icon: "/caseStudy/Ibdsport/tech3.svg",
  },
  {
    id: 4,
    icon: "/caseStudy/Ibdsport/tech4.svg",
  },
  {
    id: 5,
    icon: "/caseStudy/Ibdsport/tech5.svg",
  },
  {
    id: 6,
    icon: "/caseStudy/Ibdsport/tech6.svg",
  },
  {
    id: 7,
    icon: "/caseStudy/Ibdsport/tech7.svg",
  },
  {
    id: 8,
    icon: "/caseStudy/Ibdsport/tech8.svg",
  },
  {
    id: 9,
    icon: "/caseStudy/Ibdsport/tech9.svg",
  },
  {
    id: 10,
    icon: "/caseStudy/Ibdsport/tech10.svg",
  },
  {
    id: 11,
    icon: "/caseStudy/Ibdsport/tech11.svg",
  },
  {
    id: 12,
    icon: "/caseStudy/Ibdsport/tech12.svg",
  },
  {
    id: 13,
    icon: "/caseStudy/Ibdsport/tech13.svg",
  },
]

export const aboutProjectIcons = [
  { id: 1, icon: "/caseStudy/Ibdsport/about1.svg" },
  { id: 2, icon: "/caseStudy/Ibdsport/about2.svg" },
  { id: 3, icon: "/caseStudy/Ibdsport/about3.svg" },
]

export const keyChallenges = [
  {
    id: 1,
    title: "Inadequate Manual Management System",
    para: "Schools face a challenge of managing high-volume photography events for athletes due to the lack of a suitable manual management system.",
  },
  {
    id: 2,
    title: "Identifying and Reaching Out to Suitable Photographers",
    para: "There is difficulty in identifying and reaching out to suitable photographers for such events, making it challenging for schools to manage these events.",
  },
  {
    id: 3,
    title: "Time and Effort Required to Finalize Photographs",
    para: "Significant time and effort is required to finalize photographs that meet the expectations of each sports team or athlete, which can be a daunting task for schools.",
  },
  {
    id: 4,
    title: "Need for a Robust and Efficient Solution",
    para: "To streamline operations and reduce overall burden and time consumption, there is a need for a robust and efficient solution that can manage all aspects of photography or sports events.",
  },
]

export const solutions = [
  {
    id: 1,
    heading: "Automated System",
    description:
      "Custom platform to address inefficiencies in manual management system for high-volume photography",
    icon: "/caseStudy/Ibdsport/solution1.svg",
  },
  {
    id: 2,
    heading: "Creative-Sports Bridge",
    description:
      "Platform serves as a bridge between creative professionals and the sports sector",
    icon: "/caseStudy/Ibdsport/solution2.svg",
  },
  {
    id: 3,
    heading: "Real-Time Portrait Generation",
    description:
      "Leverages the power of Photoshop I/O API to generate customized sports portraits in real-time",
    icon: "/caseStudy/Ibdsport/solution3.svg",
  },
  {
    id: 4,
    heading: "Comprehensive Photo Editing",
    description:
      "Raw photos undergo comprehensive editing process for final product",
    icon: "/caseStudy/Ibdsport/solution4.svg",
  },
  {
    id: 5,
    heading: "Streamlined Payment Process",
    description: "Payment process streamlined using Stripe",
    icon: "/caseStudy/Ibdsport/solution5.svg",
  },
  {
    id: 6,
    heading: "Home Delivery Service",
    description: "Finalized photographs delivered to individual's doorstep",
    icon: "/caseStudy/Ibdsport/solution6.svg",
  },
]

export const featuresList = [
  {
    id: 1,
    feature: "Photo Gallery",
    icon: "/caseStudy/Ibdsport/feature1.svg",
    icon2: "/caseStudy/Ibdsport/feature11.svg",
    image: "/caseStudy/Ibdsport/IbdFeature1.png",
    paragraph:
      "Photo Gallery for managing operations like uploading, selecting, and accessing images",
  },
  {
    id: 2,
    feature: "Payment",
    icon: "/caseStudy/Ibdsport/feature2.svg",
    icon2: "/caseStudy/Ibdsport/feature12.svg",
    image: "/caseStudy/Ibdsport/IbdFeature2.png",
    paragraph: "Payment processing through Stripe.",
  },
  {
    id: 3,
    feature: "Generate QR",
    icon: "/caseStudy/Ibdsport/feature3.svg",
    icon2: "/caseStudy/Ibdsport/feature13.svg",
    image: "/caseStudy/Ibdsport/IbdFeature3.png",
    paragraph: "QR code generation and scanning.",
  },
  {
    id: 4,
    feature: "File Sharing",
    icon: "/caseStudy/Ibdsport/feature4.svg",
    icon2: "/caseStudy/Ibdsport/feature14.svg",
    image: "/caseStudy/Ibdsport/IbdFeature4.png",
    paragraph:
      "CSV file uploading feature for sharing the list of registered team members.",
  },
  {
    id: 5,
    feature: "Picture Editing",
    icon: "/caseStudy/Ibdsport/feature5.svg",
    icon2: "/caseStudy/Ibdsport/feature15.svg",
    image: "/caseStudy/Ibdsport/IbdFeature5.png",
    paragraph: "Picture editing using the Adobe Photoshop API.",
  },
  {
    id: 6,
    feature: "Printing Process",
    icon: "/caseStudy/Ibdsport/feature6.svg",
    icon2: "/caseStudy/Ibdsport/feature16.svg",
    image: "/caseStudy/Ibdsport/IbdFeature6.png",
    paragraph: "Printing process handling using the Printful API.",
  },
  {
    id: 7,
    feature: "Email Notification",
    icon: "/caseStudy/Ibdsport/feature7.svg",
    icon2: "/caseStudy/Ibdsport/feature17.svg",
    image: "/caseStudy/Ibdsport/IbdFeature7.png",
    paragraph:
      "Email notifications to registered members once their pictures were uploaded using SendGrid.",
  },
  {
    id: 8,
    feature: "Image Processing",
    icon: "/caseStudy/Ibdsport/feature8.svg",
    icon2: "/caseStudy/Ibdsport/feature18.svg",
    image: "/caseStudy/Ibdsport/IbdFeature8.png",
    paragraph: `Image processing and manipulation using the OpenCV library\n
       a. Enhancing images.\n
       b. Resizing images.\n
       c. Adjusting the Brightness.`,
  },
]

export const resultsList = [
  {
    id: 1,
    heading: "Streamlined Operations",
    description: `The platform has significantly streamlined the operations involved in managing high-volume photography events, reducing the overall time and effort required.`,
    icon: "/caseStudy/Ibdsport/result1.svg",
  },
  {
    id: 2,
    heading: "Payment Processing",
    description:
      "The integration of Stripe has simplified and expedited the payment process, allowing for seamless transactions between all parties involved.",
    icon: "/caseStudy/Ibdsport/result4.svg",
  },
  {
    id: 3,
    heading: "Convenient Delivery",
    description:
      "The platform ensures that the finalized photographs are delivered to each member's doorstep, providing a convenient and hassle-free experience.",
    icon: "/caseStudy/Ibdsport/result5.svg",
  },
  {
    id: 4,
    heading: "Improved Communication",
    description:
      "It has facilitated effective communication between creative agencies, professionals, athletes, and schools, leading to better coordination and efficient outcomes.",
    icon: "/caseStudy/Ibdsport/result2.svg",
  },
  {
    id: 5,
    heading: "Customized Sports Portraits",
    description:
      "The use of the Photoshop I/O API has enabled the creation of customized sports portraits based on pre-set templates, providing a unique and personalized experience for each athlete or sports team.",
    icon: "/caseStudy/Ibdsport/result3.svg",
  },
]

export const businessNeedsList = [
  {
    id: 1,
    heading: "Cloud-Based Sports Platform",
    description: "",
    icon: "/caseStudy/Ibdsport/business1.svg",
  },
  {
    id: 2,
    heading: "Automated Photo Access",
    description: "",
    icon: "/caseStudy/Ibdsport/business2.svg",
  },
  {
    id: 3,
    heading: "Efficient Photographer Allocation",
    description: "",
    icon: "/caseStudy/Ibdsport/business3.svg",
  },
]
