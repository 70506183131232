import React, { useEffect } from "react"
import Layout from "../../components/Layout"
 
import IbdSports from "../../components/IbdSport"
export default function TrackHero() {
  
  

  return (
    <>
      <Layout>
        <IbdSports />
      </Layout>
      
    </>
  )
}
